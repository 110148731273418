import React from 'react'
import { Box, HStack, VStack, Stack, Text, Button, ButtonGroup, Divider} from '@chakra-ui/react'

const Footer = () => {

  return (

        <div>
  
          <footer className="footer section-padding" style={{background: 'linear-gradient(150deg, rgba(16,32,73,0.8015581232492998) 7%, rgba(15,33,70,0) 63%)'}}>
            <div className="container">
              <Box className="row" justifyContent={'space-between'}>

                <div className="col-12 col-lg-2">
                  <div className="mb-4 mb-lg-0">
                    <a href="/"><img className="pb-3" src="images/logo/logo-dark.png" width={200} loading="lazy" alt="…" /></a>
                    <p className="text-white mb-10 mt-10"> Collect and Sell NFTs with Us</p>

                      <VStack>
                          <Divider style={{marginBottom: 15, opacity: 0.3}} />
                            <ul className="social list-group list-group-horizontal justify-content-center justify-content-sm-end">
                              <li className="list-group-item border-0 bg-transparent p-0 text-white">
                                  <Text style={{fontSize: 18}}>Follow us: </Text>
                              </li>
                              <li className="list-group-item border-0 bg-transparent p-0">
                                <a style={{fontSize: 20}} href="#" target="_blank" className="text-white px-3"><i className="ri-telegram-fill"></i></a>
                              </li>
                              <li className="list-group-item border-0 bg-transparent p-0">
                                <a style={{fontSize: 20}} href="#" target="_blank" className="text-white px-3"><i className="ri-twitter-fill"></i></a>
                              </li>
                              <li className="list-group-item border-0 bg-transparent p-0">
                                <a style={{fontSize: 20}} href="#" target="_blank" className="text-white px-3"><i className="ri-instagram-fill"></i></a>
                              </li>
                            </ul>
                          <Divider style={{marginTop: 15, opacity: 0.3}} />
                      </VStack>
                    
                  </div>
                </div>

                <div className="col-lg-6">
                  <Box className="row g-5" justifyContent={'flex-end'}>
                    <div className="col-lg-3">
                        <a href="/" className='text-white'>Home</a>
                    </div>
                    <div className="col-lg-3">
                        <a href="/dashboard" className='text-white'>Explore</a>
                    </div>
                    <div className="col-lg-3">
                        <a href="/store" className='text-white'>Market Place</a>
                    </div>

                    {/* <div className="col-lg-3">
                        <a href="#" className='text-white'>About Us</a>
                    </div>

                    <div className="col-lg-3">
                        <a href="#" className='text-white'>Contact Us</a>
                    </div> */}

                    <div className="col-lg-3">
                        <a href="/login" className='text-white'>Sign In</a>
                    </div>

                    <div className="col-lg-3">
                        <a href="/register" className='text-white'>Sign Up</a>
                    </div>
                  </Box>
                  <Divider style={{marginTop: 15, marginBottom: 30, opacity: 0.3}} />

                  <Box className="row g-4" justifyContent={'flex-end'}>
                    <div className="col-lg-6">
                      <a href="/terms-and-conditions" className='text-white'>Terms of Digital Asset Sale</a>
                    </div>
                    <div className="col-lg-3">
                      <a href="/about-us" className='text-white'>About Us</a>
                    </div>
                    <div className="col-lg-3">
                      <a href="/about-us#faq" className='text-white'>FAQ</a>
                    </div>
                  </Box>

                  <Box className="row g-4" justifyContent={'flex-end'}>
                    <div className="col-lg-12">
                      <Divider style={{marginTop: 15, opacity: 0.3}} />
                    </div>
                  </Box>
                </div>
                
       
              </Box>

                <Text className='brand-text text-center' marginTop={20} fontSize={17}>
                Pixel Vaults NFT | Collect and Sell NFTs with Pixel Vaults NFT
                </Text>
            </div>
          </footer>

        </div>
        

  )

}


export default Footer