import React from 'react'
import { Box, HStack, VStack, Stack, Text, Button, Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { Link } from '@inertiajs/react'


const Header = ({auth}) => {
  
  console.log(window.location.pathname)

  return (

    <header className="header sticky-top">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <div className="navigation navigation-2"> */}
            <div className="navigation">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/"><img src="images/logo/logo-dark.png" loading="lazy" alt="…" />
                </a>
                <Menu>
                  <MenuButton as={Button} rightIcon={  <span className="navbar-toggler-icon" />} size={'sm'} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                  </MenuButton>
                  <MenuList>
                    <MenuItem><a href='/' className={`${window.location.pathname == '/' ? 'text-muted' : ''}`}>Home</a></MenuItem>
                    <MenuItem><a href="/about-us" className={`${window.location.pathname == '/about-us' ? 'text-muted' : ''}`}>About</a></MenuItem>
                    <MenuItem><a href="/dashboard">Explore</a></MenuItem>
                    <MenuItem><a href="/store">Market Place</a></MenuItem>
                    <MenuItem><Link href={`${auth?.user ? '/logout' : '/register'}`} method={`${auth?.user ? 'post' : 'get'}`}> {auth?.user ? 'Logout' : 'Register'}</Link></MenuItem>
                    <MenuItem><a href={`${auth?.user ? '/dashboard' : '/login'}`}> {auth?.user ? 'Dashboard' : 'Login'}</a></MenuItem>
                  </MenuList>
                </Menu>
                {/* <Button size={'sm'} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon" />
                </Button> */}
                <div className="navbar-collapse justify-content-end" id="navbarScroll">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className={`nav-link ${window.location.pathname == '/' ? 'text-white' : ''}`} href="/">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${window.location.pathname == '/about-us' ? 'text-white' : ''}`} href="/about-us">About Us</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/dashboard">Explore</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/store">Market Place</a>
                    </li>
                    {/* <li className="nav-item">
                      <a className="nav-link" href="#">About Us</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Contact Us</a>
                    </li> */}
                    <li className="nav-item mt-3 mt-lg-0">
                        <HStack spacing={3}>
                         {
                            auth?.user ? (
                              <Link className="d-sm-none d-md-block nav-link text-white" href={'/logout'} method='post'>Logout</Link>
                            ) : ( <a className="d-sm-none d-md-block nav-link text-white" href="/register">Register</a>)
                         }
                          <Divider className='d-sm-none d-lg-block' orientation='vertical' style={{height: 25, borderWidth: 1}} />
                          <a style={{fontSize: 19, marginLeft: 15}} className="nav-link btn btn-primary brand-bg text-dark rounded-pill" href={auth?.user ? '/dashboard' : '/login'}>{auth?.user ? 'Dashboard' : 'Login'}</a>
                        </HStack>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

  )

}

export default Header