import react from 'react'
import '../../../public/home/css/style.css'
import '../../../public/home/css/custom.css'
import '../../../public/assets/icons/remix-icon/remixicon.css'
import '../../../public/home/plugins/jquery/jquery.min.js'
import '../../../public/home/plugins/bootstrap/js/bootstrap.bundle.min.js'
import '../../../public/home/js/scripts.js'
// import '../../../public/assets/js/custom.js'
import Header from '@/Includes/Guest/Header'
import Footer from '@/Includes/Guest/Footer'

const Landing = ({children, auth}) => {
    

    return (
        <>
            <Header auth={auth} />
                {children}
            <Footer />
        </>
    )
}

export default Landing